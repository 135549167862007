import { Box, Typography } from "@mui/material";
import CardSite from "./CardSite";

interface Site {
  website: string;
  visits: number;
  image: string;
  link: string;
}

interface ResultSitesProps {
  sites: Site[];
  selectedSites: Site[];
  onSelectSite: (site: Site) => void;
}

const ResultSites: React.FC<ResultSitesProps> = ({
  sites,
  selectedSites,
  onSelectSite,
}) => {
  const excludedSites = ["bakecaincontrii", "bacirosa", "piccoletrasgressioni", "moscarossa"];

  const sitesForPacoto = sites.filter(
    (site) => !excludedSites.includes(site.website.toLowerCase())
  );

  const pacotoVisits = sitesForPacoto.reduce(
    (total, site) => total + site.visits,
    0
  );

  const pacotoSite: Site = {
    website: "Pacoto Incontri",
    visits: pacotoVisits,
    image: require("../assets/img-websites/pacoto.jpg"),
    link: "",
  };

  const sitesWithPacoto = [...sites, pacotoSite];
  sitesWithPacoto.sort((a, b) => b.visits - a.visits);

  return (
    <Box
      sx={{
        height: "auto",
        padding: { xs: "1rem", sm: "2rem" },
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        background: "linear-gradient(to bottom, #313131, #fd7b2b)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: { xs: "100%", sm: 600 },
        }}
      >
        {pacotoSite.visits > 0 ? (
          sitesWithPacoto.map((site) => (
            <CardSite
              key={site.website}
              site={site}
              isSelected={
                !!selectedSites.find((s) => s.website === site.website)
              }
              onSelect={() => onSelectSite(site)}
            />
          ))
        ) : (
          <Typography
            variant="h6"
            sx={{
              color: "white",
              textAlign: "center",
              padding: "2rem",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
            }}
          >
            Non sono stati trovati siti per questa categoria e provincia.
            Contattateci per trovare una soluzione!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ResultSites;
