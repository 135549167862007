import * as itHeader from "./locales/it/header.json";
import * as itSearchdata from "./locales/it/searchdata.json";

import * as esHeader from "./locales/es/header.json";
import * as esSearchdata from "./locales/es/searchdata.json";
import * as itFooter from "./locales/it/footer.json";
import * as itContact from "./locales/it/contact.json";
import { Lang } from "../types/data";

export const fallbackLng = "it";
export const languages = [fallbackLng, "es"];
export const defaultNS = "common";

export function getOptions() {
  return {
    lng: process.env.CRA_PUBLIC_LANG as Lang,
    supportedLngs: languages,
    fallbackLng,
    defaultNS,
    resources: {
      it: {
        header: itHeader,
        searchdata: itSearchdata,
        footer: itFooter,
        contact: itContact,
      },
      es: {
        header: esHeader,
        searchdata: esSearchdata,
      },
    },
  };
}
