import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ProvinceAutocomplete from "./ProvinceAutocomplete";
import SelectCategory from "./SelectCategory";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../i18n";

const SearchData: React.FC = () => {
  const { t } = useTranslation("searchdata");
  const [selectedCategory, setSelectedCategory] = useState<"Default" | "Trans">(
    "Default"
  );
  const [selectedCity, setSelectedCity] = useState<string>("");
  const navigate = useNavigate();

  const handleCategoryChange = (category: "Default" | "Trans") => {
    setSelectedCategory(category);
  };

  const handleCitySelect = (city: string) => {
    setSelectedCity(city);
  };

  const handleSearch = () => {
    if (selectedCity) {
      navigate(
        `/risultati/${encodeURIComponent(
          selectedCity
        )}?categoria=${selectedCategory}`
      );
    }
  };

  const logo = require("../assets/BackgroundSearchProvince.png");

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        minHeight: { xs: "30vh", sm: "60vh" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: 2, sm: 4, md: 6 },
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${logo})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          filter: "grayscale(100%) brightness(30%)",
        }}
      />

      <Typography
        variant="h1"
        sx={{
          fontFamily: `"Instrument Serif", "Instrument Serif Placeholder", "serif"`,
          fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
          textAlign: "center",
          color: "white",
          wordBreak: "break-word",
          position: "relative",
          zIndex: 1,
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        {t("category.label")}
      </Typography>

      <SelectCategory
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange}
      />

      <Typography
        variant="h1"
        sx={{
          fontFamily: `"Instrument Serif", "Instrument Serif Placeholder", "serif"`,
          fontSize: { xs: "2rem", sm: "3rem", md: "4rem" },
          textAlign: "center",
          color: "white",
          wordBreak: "break-word",
          position: "relative",
          zIndex: 1,
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        {t("province.label")}
      </Typography>

      <ProvinceAutocomplete
        placeholder={t("province.placeholder")}
        sx={{ width: { xs: "100%", sm: "90%" } }}
        onSelect={handleCitySelect}
      />

      <Button
        variant="contained"
        sx={{
          mt: { xs: 6, sm: 8 },
          backgroundColor: "#fd7b2b",
          color: "black",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#e86a1f",
          },
          width: { xs: "50%", sm: "90%" },
          borderRadius: 2,
        }}
        onClick={handleSearch}
        disabled={!selectedCity}
      >
        {t("button")}
      </Button>
    </Box>
  );
};

export default SearchData;
