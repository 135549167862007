import React from "react";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "../i18n";

interface Site {
  website: string;
  visits: number;
  image: string;
  link: string;
}

interface ContactButtonsProps {
  selectedSites: Site[];
}

const ContactButtons: React.FC<ContactButtonsProps> = ({ selectedSites }) => {
  const { t } = useTranslation("contact");

  const whatsAppUrl = useMemo(() => {
    let message = t("message_default");
    if (selectedSites.length === 0) {
      message = t("message_default");
    } else {
      const selectedWebsites = selectedSites
        .map((site) => `*• ${site.website}*`)
        .join("\n");
      message = t("message_selected") + "\n\n" + selectedWebsites;
    }
    return `https://wa.me/${t("phone")}?text=${message}`;
  }, [selectedSites, t]);

  return (
    <>
      <Button
        href={whatsAppUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          width: { xs: "100%", sm: "40%" },
          backgroundColor: "white",
          color: "rgb(51, 51, 51)",
          fontFamily: "'Instrument Sans', 'Instrument Sans Placeholder', serif",
          fontSize: { xs: "1rem", sm: "1.2rem" },
          padding: "0.5rem 1rem",
          marginTop: "2rem",
          borderRadius: "0.7rem",
          textTransform: "none",
        }}
      >
        Chat WhatsApp
      </Button>
      <Button
        href="tel:+390519526950"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          width: { xs: "100%", sm: "40%" },
          backgroundColor: "#000",
          color: "#fd7b2b",
          fontFamily: "'Instrument Sans', 'Instrument Sans Placeholder', serif",
          fontSize: { xs: "1rem", sm: "1.2rem" },
          padding: "0.5rem 1rem",
          marginTop: "1rem",
          borderRadius: "0.7rem",
          textTransform: "none",
        }}
      >
        Chiamata telefonica
      </Button>
    </>
  );
};

export default ContactButtons;
