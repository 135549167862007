import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ProcessedData, Site } from "../types/processedData";
import { fetchProcessedData } from "../services/dataService";
import { italyProvinces } from "../utils/italyProvinces";

const useResultLogic = () => {
  const { provincia } = useParams<{ provincia: string }>();
  const [selectedSites, setSelectedSites] = useState<Site[]>([]);
  const [provinceSites, setProvinceSites] = useState<Site[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<"Default" | "Trans">(
    "Default"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const normalizeProvince = useCallback(
    (provincia?: string): string | undefined => {
      if (!provincia) return undefined;
      const normalizedLower = provincia.toLowerCase();
      const match = italyProvinces.find(
        (p) => p.toLowerCase() === normalizedLower
      );
      return match || provincia;
    },
    []
  );

  const getSiteImage = useCallback((website: string): string => {
    const formattedName = website;
    try {
      return require(`../assets/img-websites/${formattedName}.jpg`);
    } catch (error) {
      console.error(`Imagen no encontrada para el sitio: ${website}`, error);
      return "";
    }
  }, []);

  const getSiteLink = useCallback((website: string): string => {
    switch (website) {
      case "it.simpleescorts":
        return `https://${website}.com`;
      case "moscarossa":
        return `https://www.${website}.biz`;
      case "incontriamoci":
        return `https://www.${website}.xxx`;
      case "trovagnocca":
        return `https://www.trovagnocca.com`;
      case "itaincontri":
        return `https://www.itaincontri.com`;
      case "paginelucirosse":
        return `https://www.${website}.it`;
      case "piccoletrasgressioni":
        return `https://www.${website}.it`;
      default:
        return `https://www.${website}.com`;
    }
  }, []);

  const processData = useCallback(
    (
      data: ProcessedData,
      provincia: string,
      categoria: "Default" | "Trans"
    ) => {
      const categoryData = data[provincia as keyof ProcessedData]?.[categoria];
      if (!categoryData) {
        setProvinceSites([]);
        return;
      }

      const sites: Site[] = Object.entries(categoryData).reduce<Site[]>(
        (acc, [website, siteData]) => {
          const formattedWebsite = website;

          const existingSite = acc.find(
            (site) => site.website === formattedWebsite
          );

          if (existingSite) {
            existingSite.visits += siteData.visits;
          } else {
            acc.push({
              website: formattedWebsite,
              visits: siteData.visits,
              image: getSiteImage(formattedWebsite),
              link: getSiteLink(formattedWebsite),
            });
          }

          return acc;
        },
        []
      );

      let sitesToShow: Site[] = [];

      if (categoria === "Trans") {
        sitesToShow = sites.filter((site) => site.visits > 150);
      } else {
        const sitesOver5000 = sites.filter((site) => site.visits > 5000);
        sitesToShow = sitesOver5000;

        if (sitesOver5000.length < 4) {
          const sitesUnder5000 = sites
            .filter((site) => site.visits <= 5000)
            .sort((a, b) => b.visits - a.visits);
          sitesToShow = [...sitesOver5000, ...sitesUnder5000].slice(0, 4);
        }
      }

      setProvinceSites(sitesToShow);
      setSelectedSites([]);
    },
    [getSiteImage, getSiteLink]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      const normalizedProvincia = normalizeProvince(provincia);

      if (!normalizedProvincia) {
        setProvinceSites([]);
        setSelectedSites([]);
        setIsLoading(false);
        return;
      }

      try {
        const processedData: ProcessedData = await fetchProcessedData();
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (processedData[normalizedProvincia as keyof ProcessedData]) {
          processData(processedData, normalizedProvincia, selectedCategory);
        } else {
          setProvinceSites([]);
          setSelectedSites([]);
        }
      } catch (err) {
        console.error("Error al obtener los datos procesados:", err);
        setError(
          "No se pudieron cargar los datos. Por favor, intenta nuevamente."
        );
        setProvinceSites([]);
        setSelectedSites([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [provincia, normalizeProvince, processData, selectedCategory]);

  const handleSelectCategory = useCallback(
    (category: "Default" | "Trans") => {
      setSelectedCategory(category);
      if (provincia) {
        const normalizedProvincia = normalizeProvince(provincia);
        if (normalizedProvincia) {
          setIsLoading(true);
          fetchProcessedData()
            .then((data) => {
              processData(data, normalizedProvincia, category);
            })
            .catch((err) => {
              console.error("Error al obtener los datos procesados:", err);
              setError(
                "No se pudieron cargar los datos. Por favor, intenta nuevamente."
              );
              setProvinceSites([]);
              setSelectedSites([]);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    },
    [normalizeProvince, processData, provincia]
  );

  const handleSelectSite = useCallback((site: Site) => {
    setSelectedSites((prevSelected) => {
      const isSelected = prevSelected.some((s) => s.website === site.website);
      if (isSelected) {
        return prevSelected.filter((s) => s.website !== site.website);
      } else {
        return [...prevSelected, site];
      }
    });
  }, []);

  return {
    provincia: normalizeProvince(provincia) || "",
    selectedSites,
    provinceSites,
    handleSelectSite,
    handleSelectCategory,
    selectedCategory,
    isLoading,
    error,
  };
};

export default useResultLogic;
