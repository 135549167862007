import { Box, Typography } from "@mui/material";
import ProvinceAutocomplete from "./ProvinceAutocomplete";
import SelectCategory from "./SelectCategory";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "../i18n";

interface ResultHeaderProps {
  selectedProvince: string;
}

const ResultHeader: React.FC<ResultHeaderProps> = ({ selectedProvince }) => {
  const { t } = useTranslation("header");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoria =
    (queryParams.get("categoria") as "Default" | "Trans") || "Default";

  const handleCategoryChange = (category: "Default" | "Trans") => {
    navigate(
      `/risultati/${encodeURIComponent(selectedProvince)}?categoria=${category}`
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: { xs: "65vh", sm: "90vh" },
        backgroundColor: "#313131",
        padding: { xs: "0 1rem", sm: "0 2rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          flex: 1,
        }}
      >
        <Typography
          variant="h1"
          onClick={() => navigate("/")}
          sx={{
            fontFamily: "'Staatliches', sans-serif",
            fontSize: { xs: "4rem", sm: "9rem" },
            lineHeight: "1em",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: { xs: "3vh", sm: "3vh" },
            textTransform: "uppercase",
          }}
        >
          {t("result.title")}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontFamily: "'Staatliches', sans-serif",
            fontSize: { xs: "2rem", sm: "4rem" },
            lineHeight: "1em",
            color: "#fd7b2b",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          {selectedProvince}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily:
              "'Instrument Sans', 'Instrument Sans Placeholder', serif",
            fontSize: { xs: "1.2rem", sm: "2.2rem" },
            lineHeight: "1.4em",
            marginTop: "1rem",
            color: "white",
            padding: "0 2rem",
          }}
        >
          {t("result.description")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "1rem",
            marginTop: { xs: "1rem", sm: "2rem" },
          }}
        >
          <SelectCategory
            selectedCategory={categoria}
            onCategoryChange={handleCategoryChange}
          />
          <ProvinceAutocomplete
            placeholder="CERCA UN'ALTRA PROVINCIA"
            sx={{
              width: { xs: "100%", sm: "100%" },
              marginBottom: { xs: "1rem", sm: "2rem" },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginBottom: { xs: "1rem", sm: "2rem" },
          backgroundImage: `url(${require("../assets/photoResultHeader.jpg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: 5,
          filter: "grayscale(100%)",
          overflow: "hidden",
          position: "relative",
        }}
      />
    </Box>
  );
};

export default ResultHeader;
