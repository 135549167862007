import { Box, Typography } from "@mui/material";
import ResultHeader from "../components/ResultHeader";
import ResultSites from "../components/ResultSites";
import FooterResult from "../components/FooterResult";
import LoadingOverlay from "../components/LoadingOverlay";
import useResultLogic from "../hooks/useResultLogic";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Result: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoria =
    (queryParams.get("categoria") as "Default" | "Trans") || "Default";

  const {
    provincia,
    selectedSites,
    provinceSites,
    handleSelectSite,
    handleSelectCategory,
    isLoading,
    error,
  } = useResultLogic();

  useEffect(() => {
    handleSelectCategory(categoria);
  }, [categoria, handleSelectCategory]);

  if (error) {
    return (
      <Box sx={{ padding: "2rem", textAlign: "center" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <ResultHeader selectedProvince={provincia} />
      <ResultSites
        sites={provinceSites}
        selectedSites={selectedSites}
        onSelectSite={handleSelectSite}
      />
      <FooterResult selectedSites={selectedSites} />
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

export default Result;
