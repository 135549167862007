import React from "react";
import { Box } from "@mui/material";
import { HourglassEmpty } from "@mui/icons-material";

interface LoadingOverlayProps {
  isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        display: "flex",
        justifyContent: "center",
        zIndex: 9999,
        alignItems: { md: "center" },
      }}
    >
      <HourglassEmpty
        sx={{
          marginTop: { xs: "25rem", md: "0" },
          color: "#fd7b2b",
          fontSize: 60,
          animation: "spin 2s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />
    </Box>
  );
};

export default LoadingOverlay;
