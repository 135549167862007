import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "../i18n";

interface SelectCategoryProps {
  selectedCategory: "Default" | "Trans";
  onCategoryChange: (category: "Default" | "Trans") => void;
}

const SelectCategory: React.FC<SelectCategoryProps> = ({
  selectedCategory,
  onCategoryChange,
}) => {
  const { t } = useTranslation("searchdata");
  const handleChange = (event: SelectChangeEvent<"Default" | "Trans">) => {
    onCategoryChange(event.target.value as "Default" | "Trans");
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        width: { xs: "100%", sm: "90%" },
        marginBottom: 2,
        position: "relative",
        zIndex: 1,
      }}
    >
      <Select
        labelId="category-select-label"
        id="category-select"
        value={selectedCategory}
        label="Categoría"
        onChange={handleChange}
        sx={{
          backgroundColor: "#c8c8c8",
          borderRadius: 2,
          textAlign: "center",
          color: "#fd7b2b",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#fd7b2b",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fd7b2b",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fd7b2b",
          },
          ".MuiSvgIcon-root": {
            color: "#fd7b2b",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "#c8c8c8",
              borderRadius: 2,
              color: "#fd7b2b",
            },
          },
        }}
        displayEmpty
      >
        <MenuItem value="Default">{t("category.meetings")}</MenuItem>
        <MenuItem value="Trans">{t("category.trans")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectCategory;
