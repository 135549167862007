import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./HeroSection.module.css";
import { Trans } from "react-i18next";
import { useTranslation } from "../i18n";

const HeroSection = () => {
  const { t } = useTranslation("footer");
  return (
    <Box
      height={{ xs: "auto", sm: "30vh" }}
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        padding: { xs: "1rem", sm: "2rem" },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          marginTop: { xs: "2vh", sm: "5vh" },
          fontFamily: "'Instrument Serif', serif",
          fontSize: { xs: "32px", sm: "45px" },
          color: "rgb(253, 123, 43)",
          marginBottom: { xs: "1rem", sm: "2rem" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        {t("title")}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "'Archivo', sans-serif",
          fontSize: { xs: "18px", sm: "21px" },
          lineHeight: "1.5em",
          marginBottom: { xs: "1rem", sm: "2rem" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Trans
          t={t}
          i18nKey="description"
          components={[<span className={styles.highlightedText} />]}
        />
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, sm: 12 }}
        justifyContent="center"
        marginTop="2rem"
      >
        <Grid
          size={{ xs: 4, md: 4 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "'Instrument Serif', serif",
                fontSize: { xs: "28px", sm: "32px" },
                color: "rgb(253, 123, 43)",
                marginBottom: "0.5rem",
                textAlign: "center",
              }}
            >
              100 +
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 700,
                letterSpacing: "-0.025em",
                lineHeight: "1.5em",
                textAlign: "center",
              }}
            >
              {t("data.sites")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 4, md: 4 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "'Instrument Serif', serif",
                fontSize: { xs: "28px", sm: "32px" },
                color: "rgb(253, 123, 43)",
                marginBottom: "0.5rem",
                textAlign: "center",
              }}
            >
              110
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 700,
                letterSpacing: "-0.025em",
                lineHeight: "1.5em",
                textAlign: "center",
              }}
            >
              {t("data.province")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 4, md: 4 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "'Instrument Serif', serif",
                fontSize: { xs: "28px", sm: "32px" },
                color: "rgb(253, 123, 43)",
                marginBottom: "0.5rem",
                textAlign: "center",
              }}
            >
              4
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 700,
                letterSpacing: "-0.025em",
                lineHeight: "1.5em",
                textAlign: "center",
              }}
            >
              {t("data.updates")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;
