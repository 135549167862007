import i18next from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next'
import { defaultNS, getOptions } from './settings'

i18next.use(initReactI18next).init(getOptions())

export function useTranslation(
  ns?: string,
  options: { keyPrefix?: string } = {},
  lng?: string,
) {
  const namespace = ns || defaultNS
  return {
    t: i18next.getFixedT(
      lng || i18next.language,
      Array.isArray(namespace) ? namespace[0] : namespace,
      options.keyPrefix,
    ),
    i18n: i18next,
  }
}
export default i18next
