import { Box, Typography } from "@mui/material";
import SocialLinks from "./SocialLinks";
import ContactButtons from "./ContactButtons";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { useTranslation } from "../i18n";

interface Site {
  website: string;
  visits: number;
  image: string;
  link: string;
}

interface FooterResultProps {
  selectedSites: Site[];
}

const FooterResult: React.FC<FooterResultProps> = ({ selectedSites }) => {
  const { t } = useTranslation("footer");
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#fd7b2b",
        padding: { xs: "0 1rem", sm: "0 2rem" },
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBottom: { xs: "5rem", sm: "15rem" },
        }}
      >
        <Typography
          mb={1}
          variant="body2"
          sx={{ color: "white", fontSize: "10px", textAlign: "center" }}
        >
          <Trans
            t={t}
            i18nKey="copyright"
            components={[
              <Link
                to="https://www.semrush.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "rgb(51, 51, 51)",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              />,
            ]}
          />
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontFamily: "'Staatliches', sans-serif",
            fontSize: { xs: "1.6rem", sm: "2.5rem" },
            lineHeight: "1em",
            color: "rgb(51, 51, 51)",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: { xs: "4vh", sm: "7vh" },
            textTransform: "uppercase",
          }}
        >
          {t("contact.title")}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily:
              "'Instrument Sans', 'Instrument Sans Placeholder', serif",
            fontSize: { xs: "1rem", sm: "1.2rem" },
            lineHeight: "1.5em",
            marginTop: "1rem",
            color: "rgb(51, 51, 51)",
            padding: { xs: "0 1rem", sm: "0 25rem" },
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            textDecoration: "none",
            textAlign: "center",
          }}
        >
          {t("contact.description")}
        </Typography>
        <ContactButtons selectedSites={selectedSites} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 4,
          }}
        >
          <SocialLinks color="black" />
        </Box>

        <Typography
          variant="body1"
          sx={{
            fontFamily:
              "'Instrument Sans', 'Instrument Sans Placeholder', serif",
            fontSize: { xs: "0.8rem", sm: "1rem" },
            lineHeight: "1em",
            color: "black",
            marginTop: "1rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          ©{new Date().getFullYear()} Annunciosubito
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterResult;
