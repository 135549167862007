import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import SearchData from "../components/SearchData";
import FooterHome from "../components/FooterHome";

const Home: React.FC = () => {
  return (
    <Box>
      <Header />
      <SearchData />
      <HeroSection />
      <FooterHome />
    </Box>
  );
};

export default Home;