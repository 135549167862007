import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "../i18n";

const Header: React.FC = () => {
  const { t } = useTranslation("header");
  const logo = require("../assets/LogoHeader.png");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "35vh", sm: "50vh" },
        background: "linear-gradient(to bottom, #111111, #ACB6B3)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{
            width: "80%",
            maxWidth: { xs: "100%", sm: "60%" },
            height: "auto",
            marginBottom: "1rem",
          }}
        />

        <Typography
          variant="h2"
          sx={{
            fontFamily: '"Instrument Serif", serif',
            fontSize: "25px",
            lineHeight: "1.1em",
            textAlign: "center",
            color: "rgb(255, 170, 34)",
            textTransform: "uppercase",
            marginTop: "10px",
          }}
        >
          {t("title")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
