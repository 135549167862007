import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "../i18n";

interface Site {
  website: string;
  visits: number;
  image: string;
  link: string;
}

interface CardSiteProps {
  site: Site;
  isSelected: boolean;
  onSelect: () => void;
}

const CardSite: React.FC<CardSiteProps> = ({ site, isSelected, onSelect }) => {
  const { t } = useTranslation("result");
  const isPacoto = site.website.toLowerCase() === "pacoto incontri";
  const isPiccoleTrasgressioni =
    site.website.toLowerCase() === "piccoletrasgressioni";
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: { xs: "100%", sm: 600 },
          elevation: 3,
          borderRadius: 2,
          boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          p: { xs: 1.5, sm: 2 },
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: { xs: 50, sm: 80 },
            height: { xs: 50, sm: 80 },
            borderRadius: 2,
            m: { xs: 0.5, sm: 1 },
            flexShrink: 0,
          }}
          image={site.image}
          alt={site.website}
        />
        <Box sx={{ flex: "1 1 auto", paddingLeft: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {site.website}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {site.visits} {t("visits")}
          </Typography>
        </Box>
        {!isPiccoleTrasgressioni && (
          <IconButton onClick={onSelect} sx={{ mr: 4 }}>
            {isSelected ? <CheckIcon sx={{ color: "#fd7b2b" }} /> : <AddIcon />}
          </IconButton>
        )}
        {isPacoto ? (
          <IconButton onClick={handleOpen}>
            <ArrowForwardIosIcon />
          </IconButton>
        ) : (
          <IconButton
            href={site.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </Card>

      {isPacoto && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-pacoto-title"
          aria-describedby="modal-pacoto-description"
        >
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 1,
              outline: "none",
              width: { xs: "90%", sm: "70%", md: "60%" },
              maxHeight: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={site.image}
              alt={`${site.website} - Imagen Grande`}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "90vh",
                borderRadius: 8,
              }}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CardSite;
