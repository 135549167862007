import { Box, Typography } from "@mui/material";
import SocialLinks from "./SocialLinks";
import { Link } from "react-router-dom";
import { useTranslation } from "../i18n";
import { Trans } from "react-i18next";

const FooterHome: React.FC = () => {
  const { t } = useTranslation("footer");
  return (
    <Box
      sx={{
        backgroundColor: "#000",
        minHeight: { xs: "10vh", sm: "15vh" },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <SocialLinks color="white" />
        <Typography
          mb={1}
          variant="body2"
          sx={{ color: "white", fontSize: "10px", textAlign: "center" }}
        >
          <Trans
            t={t}
            i18nKey="copyright"
            components={[
              <Link
                to="https://www.semrush.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "rgb(253, 123, 43)", textDecoration: "none" }}
              />,
            ]}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterHome;
