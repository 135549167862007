import React, { useState, useMemo, ChangeEvent, SyntheticEvent } from "react";
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { italyProvinces } from "../utils/italyProvinces";
import { useNavigate } from "react-router-dom";

interface ProvinceAutocompleteProps {
  placeholder: string;
  sx?: SxProps<Theme>;
  onSelect?: (value: string) => void;
}

const ProvinceAutocomplete: React.FC<ProvinceAutocompleteProps> = ({
  placeholder,
  sx = {},
  onSelect,
}) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<string[]>([]);

  const handleInputChange = (event: ChangeEvent<{}>, value: string): void => {
    setInputValue(value);
    if (value.trim().length > 0) {
      const filteredProvinces = italyProvinces.filter((province) =>
        province.toLowerCase().includes(value.toLowerCase())
      );
      setOptions(filteredProvinces);
    } else {
      setOptions([]);
    }
  };

  const handleOptionSelect = (
    event: SyntheticEvent<Element, Event>,
    value: string | null
  ): void => {
    if (value) {
      onSelect
        ? onSelect(value)
        : navigate(`/risultati/${encodeURIComponent(value)}`);
    }
  };
  const autocompleteOptions = useMemo(() => options, [options]);

  return (
    <Autocomplete
      freeSolo
      options={autocompleteOptions}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          sx={{
            backgroundColor: "#c8c8c8",
            borderRadius: 2,
            textAlign: "center",
            "& .MuiInputBase-input": {
              textAlign: "center",
              color: "#fd7b2b",
              fontWeight: "bold",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#fd7b2b",
              },
              "&:hover fieldset": {
                borderColor: "#fd7b2b",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fd7b2b",
              },
            },
          }}
        />
      )}
    />
  );
};

export default React.memo(ProvinceAutocomplete);
