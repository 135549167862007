import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import styles from "./FooterHome.module.css";

const SocialLinks: React.FC<{ color: string }> = ({ color }) => {
  const buscomasIcon = require("../assets/buscomas_icon.png");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Link
        to="https://www.instagram.com/annunciosubito/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon sx={{ color: color, fontSize: 30 }} />
      </Link>
      <Link
        to="https://www.buscomas.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={buscomasIcon}
          alt="Buscomas"
          className={styles.buscomasIcon}
        />
      </Link>
      <Link
        to="https://www.facebook.com/cerebrosenvuelo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon sx={{ color: color, fontSize: 30 }} />
      </Link>
    </Box>
  );
};

export default SocialLinks;
